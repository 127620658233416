import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from 'reactstrap'
import '../styles/404.scss'
import { StaticImage } from 'gatsby-plugin-image'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container className="mt-5 mb-5" style={{ height: `70vh` }}>
        <Row>
          <Col lg="6">
            <div className="_404-content">
              <h1 className="_404-title">404</h1>
              <p>
                Uh oh! It looks like the page you are looking for doesn't exist.{' '}
              </p>
            </div>
          </Col>
          <Col lg="6">
            <StaticImage
              src="../images/tooth-search.jpg"
              alt="Tooth icon"
              placeholder="none"
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
